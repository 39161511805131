<template>
  <v-layout column class="mb-1">
    <Autocomplete
      :value.sync="internalValue"
      label="Kraj"
      :data="items"
      :clearAfterSelection="false"
      :returnObject="false"
      :required="required"
      :autosave="autosave"
      :syncId="syncId"
      :hideDetails="false"
      :rules="[(v) => !!v || 'Pole nie może być puste.']"
      placeholder="Wybierz kraj..."
    >
    </Autocomplete>
  </v-layout>
</template>

<script>
import ProxyModificationMixin from "@/mixins/ProxyModificationMixin";

const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/pl.json"));

export default {
  mixins: [ProxyModificationMixin],
  props: {
    value: {},
    required: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Autocomplete: () => import("@/components/Autocomplete"),
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
    items() {
      const list = countries.getNames("pl", { select: "official" });
      return Object.keys(list).map((key) => ({ value: key, text: list[key] }));
    },
  },
};
</script>